@import '@socar-inc/socar-design-system/styles';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './font.css';
@import './webview.css';
@import './socar-ui-style.css';

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer utilities {
  .bottom-sheet-cta-gradient {
    --tw-gradient-from: #ffffff 0%;
    --tw-gradient-to: rgb(255, 255, 255, 0) 100%;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }
}
